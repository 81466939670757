import { create_div } from 'js/utils';

import './login_page.scss';

const settings = JSON.parse(login_page_settings);

function init(){

    if( settings.type == 'form_center' ) {
        form_center_init();
    }
    else {
        form_left_init();
    }

    add_placeholders();
    render_bg();
    render_logo();
}
init();

function form_left_init(){
    import('./form_left.scss');

    const login = document.getElementById('login');

    // left
    const left_con = create_div('login_left');
    login.after(left_con);
    
    const left_inner = create_div('login_left_inner', left_con);
    left_inner.append(login)
    
    // right
    const right_con = create_div('login_right custom_bg');
    left_con.after(right_con);
}

function form_center_init(){
    import('./form_center.scss');
}

function add_placeholders(){
    const user_login = document.getElementById('user_login');
    if( user_login ) {
        user_login.placeholder = 'Username or Email Address';
    }
    const user_pass = document.getElementById('user_pass');
    if( user_pass ) {
        user_pass.placeholder = 'Password';
    }
}

function render_bg(){
    if( !settings.bg_image ) return;

    const selector = settings.type == 'form_left' ? '.custom_bg' : 'body';

    document.querySelector(selector).style.backgroundImage = 'url('+settings.bg_image+')';
}

function render_logo(){
    if( !settings.logo ) return;

    const container = document.getElementById('loginform');
    
    const logo_container = create_div('custom_logo');
    container.prepend(logo_container);

    const logo = document.createElement('img');
    logo.src = settings.logo;
    logo_container.append(logo)
}